<template>
  <div class="jTable-loading" v-if="isLoading">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <v-date-picker is-range v-model="state.range" v-if="!isLoading">
    <template v-slot="{ inputValue, inputEvents }">
      <div class="grid grid-2">
        <div class="form-group__input">
          <span>
            <label class="t-lbl">{{ $t("message.start_date") }}</label>
            <div class="clearfix"></div>
            <input
              class="bg-white form-input border br1 px-2 py-1"
              :value="inputValue.start"
              v-on="inputEvents.start"
            />
          </span>
          <span>
            <label class="t-lbl">{{ $t("message.end_date") }}</label>
            <div class="clearfix"></div>
            <input
              class="bg-white form-input border br2 px-2 py-1"
              :value="inputValue.end"
              v-on="inputEvents.end"
            />
          </span>
          <span>
            <label>&nbsp;</label>
            <button @click="filterByDates" class="form-btn ml-10">
              {{ $t("message.update") }}
            </button>
          </span>
        </div>
        <div v-if="state.routeName == 'General' && Object.keys(state.journals)">
          <label class="t-lbl">{{ $t("message.select_codes") }}</label>
          <VueMultiselect
            v-model="state.selected"
            :options="state.specialCodesArray"
            :multiple="true"
            :close-on-select="true"
            placeholder="Sélectionner un compte"
            @close="filterJournalByMagicCode"
            @remove="filterJournalByMagicCode"
          />
        </div>
        <div
          class="f-r"
          v-if="
            state.routeName == 'Third' && Object.keys(state.journals).length
          "
        >
          <Dropdown
            @get="(val) => filterJournalByMagicCode(val.company_code)"
            select_label="Selectionner"
            select_dd="client_dd"
            data-_field="company_code"
            defaultValue="false"
            :data="state.myCodes"
          ></Dropdown>
        </div>
      </div>
    </template>
  </v-date-picker>
  <div class="clearfix"></div>
  <br />
  <!-- {{ state.journals }} -->
  <div class="journal--master" v-if="Object.keys(state.journals).length">
    <div class="journal-card m0">
      <div class="journal-card__header">
        <h3 class="journal-card__title">
          {{
            state.routeName != "General"
              ? $t("message.ledger_of_third")
              : $t("message.general_ledger")
          }}
        </h3>
      </div>
      <br />
      <div class="grid d" style="--c: 2fr 1fr 0.5fr 0.5fr">
        <span class="t-lbl">Prohada 1.0.0</span>
        <span class="t-lbl"
          >Date de tirage: {{ new Date().toLocaleString() }}</span
        >
        <span class="t-lbl">{{ $t("message.time") }}: 17:26:00</span>
      </div>
      <br />
      <div class="journal-card__body">
        <div
          class="jRow head"
          style="--col: 1fr 80px 0.75fr 1.75fr 1fr 1fr 1fr"
        >
          <span>Jour</span>
          <span>C.j</span>
          <span>
            N
            <sup>◦</sup> pièce
          </span>
          <!-- <span>N<sup>◦</sup> compte</span> -->
          <!-- <span>N<sup>◦</sup> tiers</span> -->
          <span>{{ $t("message.libellè_ècriture") }}</span>
          <span class="dib t-r">{{ $t("message.Mvts_dèbit") }}</span>
          <span class="dib t-r">{{ $t("message.Mvts_crèdit") }}</span>
          <span class="dib t-r">Équilibre progressif</span>
        </div>

        <div class="jTable-loading" v-if="isLoading">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="jRow-body" v-if="!isLoading">
          <div v-for="(journalData, key) in state.journals" :key="key">
            <div class="jRow">
              <span class="jRow-col">
                <strong>{{ key }}</strong>
              </span>
            </div>
            <div
              v-for="(journal, index) in journalData"
              :key="index"
              class="jRow"
              style="--col: 1fr 80px 0.75fr 1.75fr 1fr 1fr 1fr"
            >
              <!-- <div
                class="jRow"
                v-for="journal in value"
                :key="journal.id"
                style="--col: 1fr 80px 0.75fr 1.75fr 1fr 1fr 1fr"
              >-->
              <span class="jRow-col">{{ journal.entry_date }}</span>
              <span class="jRow-col">{{ journal.label_abbr }}</span>
              <span class="jRow-col">{{ journal.part_number }}</span>
              <!-- <span class="jRow-col">{{ journal.account_number }}</span> -->
              <!-- <span class="jRow-col">{{ journal.tier_number }}</span> -->
              <span class="jRow-col">{{ journal.designation }}</span>
              <span class="jRow-col t-r">{{ journal.debit }}</span>
              <span class="jRow-col t-r">{{ journal.credit }}</span>
              <span class="jRow-col t-r">{{
                journal.progressive_balance
              }}</span>
              <!-- </div> -->
            </div>
          </div>
          <div
            class="jRow bt"
            style="--col: 1fr 80px 0.75fr 1.75fr 1fr 1fr 1fr"
          >
            <span class="jRow-col">&nbsp;</span>
            <span class="jRow-col">&nbsp;</span>
            <span class="jRow-col">&nbsp;</span>
            <span class="jRow-col"></span>
            <span class="jRow-col t-r">
              <strong>
                Total:&nbsp;&nbsp;
                <span class="t-r">{{ state.totalBalance.debit }}</span>
              </strong>
            </span>
            <span class="jRow-col t-r">
              <strong>{{ state.totalBalance.credit }}</strong>
            </span>
            <span class="jRow-col t-r">
              <strong>
                {{ state.totalBalance.progressiveBalance }}
              </strong>
            </span>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <div>
          <!-- <button @click="generatePdf">
            Generate PDF using Frontend</button
          >&nbsp;&nbsp;-->
          <button
            @click="generatePdfByFrontEnd"
            v-if="!pdf.generating"
            class="form-btn sm"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-file-text"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#ffffff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M14 3v4a1 1 0 0 0 1 1h4" />
              <path
                d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
              />
              <line x1="9" y1="9" x2="10" y2="9" />
              <line x1="9" y1="13" x2="15" y2="13" />
              <line x1="9" y1="17" x2="15" y2="17" />
            </svg>
            &nbsp;{{ $t("message.pdf") }}
          </button>
          <button class="form-btn disabled" disabled v-if="pdf.generating">
            Generating...
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="!isLoading && !Object.keys(state.journals).length"
    class="journal--master main-lbl"
  >
    <div class="journal-card m0 second-lbl">
      <div class="journal-card__body">
        <span class="lbl-gray__empty">{{ $t("message.no_data_found") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import router from "@/router";
import Dropdown from "@/components/element/Dropdown.vue";
import { codes } from "@/config/codes.json";
import VueMultiselect from "vue-multiselect";

export default {
  components: { Dropdown, VueMultiselect },
  setup() {
    const isLoading = ref(1);
    const prevDate = new Date(new Date().setDate(new Date().getDate() - 30));
    const state = reactive({
      journals: [],
      journalsObj: [],
      totalBalance: {
        debit: 0,
        credit: 0,
        progressive_balance: 0,
      },
      range: {
        start: prevDate,
        end: new Date(),
      },
      periode_du: new Date(),
      selectedType: "Tous",
      selected: [],
      specialCodesArray: [],
    });
    const pdf = ref({ generating: 0 });
    const store = useStore();
    function filterByDates() {
      fetchJournalEntries();
    }
    const client_uuid = computed(() => router.currentRoute.value.params.uuid);

    const calculateTotalBalances = () => {
      state.totalBalance.debit = 0;
      state.totalBalance.credit = 0;
      state.totalBalance.progressiveBalance = 0;
      Object.keys(state.journals).map((val) => {
        state.journals[val]?.map((j) => {
          state.totalBalance.debit += j.debit ? Number(j.debit) : 0;
          state.totalBalance.credit += j.credit ? Number(j.credit) : 0;
          state.totalBalance.progressiveBalance += j.progressive_balance
            ? Number(j.progressive_balance)
            : 0;
        });
      });
    };

    // Fetch journals on load and by updating filters.
    const fetchJournalEntries = async () => {
      isLoading.value = 1;
      let ohadaCodes = [401, 411, 4011];
      if (router.currentRoute.value.name == "General") {
        ohadaCodes = state.specialCodesArray;
      }
      await store
        .dispatch("journal/fetchEntries", {
          from_date: state.range.start,
          to_date: state.range.end,
          client_uuid: client_uuid.value,
          operation_type: "ledger-of-third",
          apiUrl: `/journal/entry/ledger/fetch`,
          isGroupedBy: 0,
          ohada_codes: ohadaCodes,
        })
        .then(async (response) => {
          // Calcluate PB
          await calculateProgressiveBalanceFromObj(response);
          state.journals = state.journalsObj = response;
          calculateTotalBalances();
          isLoading.value = 0;
        });
    };

    // Calc progressive balance
    const calculateProgressiveBalanceFromObj = (journals) => {
      Object.keys(journals).map((obj) => {
        journals[obj].map((item, i) => {
          item.label_abbr = codes.labelAbbr[item.type.toLowerCase()];
          if (i == 0) {
            item.progressive_balance = Number(item.debit) - Number(item.credit);
          } else {
            item.progressive_balance =
              Number(journals[obj][i - 1].progressive_balance) +
              Number(item.debit) -
              Number(item.credit);
          }
        });
      });
    };

    // Filter journal set(Front-end only)
    const filterJournalByMagicCode = async (val = null) => {
      console.log("Called...");
      state.journals = await state.journalsObj;
      state.journals = {};
      switch (router.currentRoute.value.name) {
        case "Third":
          if (val == "Tous") {
            state.journals = state.journalsObj;
          } else {
            state.journals[val] = state.journalsObj[val];
          }
          break;
        case "General":
          if (state.selected.length == 0) {
            state.journals = state.journalsObj;
          } else {
            state.selected.forEach((code) => {
              if (code == "Tous") state.journals = state.journalsObj;
              else if (state.journalsObj[code])
                state.journals[code] = state.journalsObj[code];
            });
          }
          break;
      }
      calculateTotalBalances();
    };
    // Create pdf using back-end
    const generatePdf = async () => {
      let ohadaCodes = [401, 411];
      if (state.selectedType != "Tous")
        ohadaCodes = [Number(state.selectedType)];
      if (router.currentRoute.value.name == "General")
        ohadaCodes = state.specialCodesArray;
      pdf.value.generating = 1;
      const pdfData = {
        from_date: state.range.start,
        to_date: state.range.end,
        ohada_codes: ohadaCodes,
        operation_type: "ledger-of-third",
        print_date: state.periode_du.toLocaleString(),
      };
      if (client_uuid.value !== undefined) {
        pdfData.client_id = state.client.id;
      }
      await store.dispatch("journal/generatePdf", pdfData).then((res) => {
        pdf.value.generating = 0;
        if (res.status == "success") {
          let linkElement = document.createElement("a");
          linkElement.setAttribute("href", res.data);
          linkElement.setAttribute("target", "_blank");
          document.body.appendChild(linkElement);
          linkElement.click();
          document.body.removeChild(linkElement);
        }
      });
    };
    // Create pdf using fron-end data
    const generatePdfByFrontEnd = async () => {
      pdf.value.generating = 1;
      const pdfLabel = state.routeName;
      // console.log(pdfLabel);
      const pdfData = {
        operation_type: "ledger-of-third",
        print_date: state.periode_du.toLocaleString(),
        frontend_data: state.journals,
        total_balance: state.totalBalance,
        data: {
          label: pdfLabel,
        },
      };
      if (client_uuid.value !== undefined) {
        pdfData.client_id = state.client.id;
      }
      await store
        .dispatch("journal/generateFrontendPdf", pdfData)
        .then((res) => {
          pdf.value.generating = 0;
          if (res.status == "success") {
            let linkElement = document.createElement("a");
            linkElement.setAttribute("href", res.data);
            linkElement.setAttribute("target", "_blank");
            document.body.appendChild(linkElement);
            linkElement.click();
            document.body.removeChild(linkElement);
          }
        });
    };

    onMounted(async () => {
      // store.commit("component/SET_LOADER", true);
      isLoading.value = 1;
      await store.dispatch("code/fetchOhadaCodes");
      // Store route name
      state.routeName = router.currentRoute.value.name;
      const specialCodesSet = new Set([]);
      Object.values(codes.specialCodes).map((c) =>
        c.map((v) => specialCodesSet.add(v.code))
      );
      if (!state.client)
        await store
          .dispatch("client/fetchClientByUUID", { uuid: client_uuid.value })
          .then((res) => {
            state.client = res.data;
          });

      // Fetch mycodes

      await store.dispatch("code/fetchMyCodes").then((res) => {
        state.myCodes = res.filter((code) => code.client_id == state.client.id);
        state.myCodes.map((c) => {
          c.code_type = "personalCode";
        });
        const staticCodes = [
          {
            company_code: 401,
            code_type: "ohadaCode",
          },
          { company_code: 411, code_type: "ohadaCode" },
          { company_code: 4011, code_type: "ohadaCode" },
        ];
        staticCodes.forEach((c) => state.myCodes.push(c));
        state.myCodes.unshift({ company_code: "Tous", code_type: "static" });
      });

      //add all codes into specialcodearray for filter in General
      Object.values(state.myCodes).map((c) => {
        specialCodesSet.add(c.company_code);
      });

      Object.values(store.state.code.codes).map((c) => {
        specialCodesSet.add(c.code);
      });

      // Convert set to arry.
      state.specialCodesArray = [...specialCodesSet];

      //Take "All" to first position for dropdown
      state.specialCodesArray.forEach((item, i) => {
        if (item === "Tous") {
          state.specialCodesArray.splice(i, 1);
          state.specialCodesArray.unshift(item);
        }
      });

      // Fetch journal entries
      await fetchJournalEntries();

      // Init default type
      state.selectedType = state.myCodes[0].company_code;
      store.commit("component/SET_LOADER", false);
      // filterJournalByMagicCode(state.myCodes[0].company_code);
    });
    return {
      pdf,
      state,
      fetchJournalEntries,
      generatePdf,
      generatePdfByFrontEnd,
      isLoading,
      filterByDates,
      filterJournalByMagicCode,
    };
  },
};
</script>
